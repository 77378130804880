import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import styled from "styled-components"
import Button from "@material-ui/core/Button"
import { Link } from "gatsby"
import Rating from "react-rating"

const StyledContainerWrpaper = styled.div`
  padding: 40px 0;
  text-align: center;
`
const StyledButton = styled(Button)`
  display: block;
  width: 100%;
`
const StyledMarginWrapper = styled.div`
  margin: 60px 0;
`
const PaymentsContactLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/contact-us" {...props} />
))
const PaymentSuccess = () => {
  return (
    <Layout>
      <SEO title="Home"/>
      <Container maxWidth={"lg"}>
        <StyledContainerWrpaper>
          <Typography>Płatność przebiegła pomyślnie!</Typography>
          <Typography>Dziękujemy za skorzystanie z naszej aplikacji!</Typography>
          <StyledMarginWrapper>
            <Typography>Jeśli możesz zostaw nam swoją opinie ;)</Typography><br/>
            <Rating/>
          </StyledMarginWrapper>
          <StyledMarginWrapper>
            <Typography>A jeśli chciałbyś do nas napisać, to formularz kontaktowy znajdziesz klikając link
              poniżej.</Typography><br/><br/>
            <StyledButton variant="contained" color="primary" size="large"
                          component={PaymentsContactLink}>Napisz do nas</StyledButton>
          </StyledMarginWrapper>
        </StyledContainerWrpaper>
      </Container>
    </Layout>
  )
}

export default PaymentSuccess
